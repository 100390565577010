import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V1 } from "shared/config";

const service = "agent/google-docs/append";
const serviceUrl = `${API_URL}${API_VERSION_V1}${service}`;

export interface RequestPayload {
  text: string;
  modelName?: string;
}

export interface GoogleDocsAppendAgentResponse {
  data: {
    text: string;
  };
}

const postQuery = async (
  payload: RequestPayload
): Promise<GoogleDocsAppendAgentResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post google docs agent query");
  }
  return await response.json();
};

export function useGoogleDocsAppendAgentQuery(): UseMutationResult<
  GoogleDocsAppendAgentResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}
