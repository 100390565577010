import {
  RequestPayload,
  Source,
  useWebSearchQueryApi,
} from "shared/api/v1/web/search/post";

interface ReturnType {
  isLoading: boolean;
  text: string;
  sources: Source[];
  handleWebSearch: (arg: RequestPayload) => void;
}

export function useWebSearchQuery(): ReturnType {
  const { mutate, isLoading, data } = useWebSearchQueryApi();

  let text = "";
  let sources = [] as Source[];

  const handleWebSearch = ({ query, threadId }: RequestPayload) => {
    mutate({ query, threadId, modelName: "mool-gpt-plus" });
  };

  if (data) {
    text = data?.data?.text;
    sources = data?.data?.sources;
  }

  return {
    isLoading,
    text,
    sources,
    handleWebSearch,
  };
}
