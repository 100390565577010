import {
  useEmailAgentQuery,
  EmailAgentResponse,
  RequestPayload,
} from "shared/api/v1/agent/email/post";

interface ReturnType {
  isLoading: boolean;
  text: string;
  handleEmailAgent: (payload: RequestPayload) => void;
}

export function useEmailAgent(): ReturnType {
  const { mutate, isLoading, data } = useEmailAgentQuery();

  const responseData = data as EmailAgentResponse;

  const handleEmailAgent = (payload: RequestPayload) => {
    mutate({
      ...payload,
      modelName: "mool-gpt-plus",
    });
  };

  return {
    isLoading,
    text: responseData?.data?.text,
    handleEmailAgent,
  };
}
