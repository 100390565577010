import {
  SlackAgentResponse,
  useSlackAgentQuery,
  RequestPayload,
} from "shared/api/v1/agent/slack/post";

interface ReturnType {
  isLoading: boolean;
  text: string;
  handleSlackAgent: (payload: RequestPayload) => void;
}

export function useSlackAgent(): ReturnType {
  const { mutate, isLoading, data } = useSlackAgentQuery();

  const responseData = data as SlackAgentResponse;

  const handleSlackAgent = (payload: RequestPayload) => {
    mutate({
      ...payload,
      modelName: "mool-gpt-plus",
    });
  };

  return {
    isLoading,
    text: responseData?.data?.text,
    handleSlackAgent,
  };
}
