import { useEffect, useState } from "react";

import { useChatSequence } from "./chat-sequence";
import { useWebSearchQuery } from "./web-query";
import { DocumentSource, Source } from "shared/api/v1/web/search/post";
import { useGoogleDocsAppend } from "./google-docs-agent";

export interface Conversation {
  type: "user" | "ai" | "file" | "ai-web";
  text: string;
  sources?: Source[];
  documentSources?: DocumentSource[];
  sqlQuery?: string;
}

interface Return {
  conversations: Conversation[];
  handleConversation: (query: string) => void;
  isLoading: boolean;
  total: number;
  handleFileUpload: (query: string) => void;
  webSearchHandler: () => void;
  googleDocsAppendHandler: () => void;
  webSearchQuery: string;
  isWebSearchLoading: boolean;
  isGoogleDocsLoading: boolean;
}

export function useConversation(repo?: string): Return {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [webSearchQuery, setWebSearchQuery] = useState("");
  const { handleChat, isLoading, text, threadId, documentSources, sqlQuery } =
    useChatSequence(repo);
  const {
    handleWebSearch,
    isLoading: isWebSearchLoading,
    text: webSearchText,
    sources,
  } = useWebSearchQuery();

  const { handleGoogleDocsAppend, isLoading: isGoogleDocsLoading } =
    useGoogleDocsAppend();

  const handleConversation = (query: string) => {
    setConversations((prev) => [...prev, { type: "user", text: query }]);
    handleChat({ query, threadId });
  };

  const handleFileUpload = (query: string) => {
    setConversations((prev) => [...prev, { type: "file", text: query }]);
  };

  const webSearchHandler = () => {
    const userQueries = conversations.filter(
      (conversation) => conversation?.type === "user"
    );
    const lastIndex = userQueries.length - 1;
    const query = userQueries[lastIndex]?.text;
    setWebSearchQuery(query);
    handleWebSearch({ query, threadId });
  };

  const googleDocsAppendHandler = () => {
    const aiTexts = conversations.filter(
      (conversation) =>
        conversation.type === "ai" || conversation.type === "ai-web"
    );
    const lastIndex = aiTexts.length - 1;
    const aiText = aiTexts[lastIndex]?.text;
    const userTexts = conversations.filter(
      (conversation) => conversation.type === "user"
    );
    const lastIndexUser = userTexts.length - 1;
    const userText = userTexts[lastIndexUser]?.text;
    const text = `
    * User : ${userText}
    DMI : ${aiText}
    `;
    handleGoogleDocsAppend({ text });
  };

  useEffect(() => {
    if (!isLoading && text) {
      setConversations((prev) => [
        ...prev,
        { type: "ai", text, documentSources, sqlQuery },
      ]);
      setWebSearchQuery("");
    }
  }, [text, isLoading]);

  useEffect(() => {
    if (!isWebSearchLoading && webSearchText) {
      setConversations((prev) => [
        ...prev,
        { type: "ai-web", text: webSearchText, sources: sources || [] },
      ]);
    }
  }, [webSearchText, isWebSearchLoading]);

  const total = conversations.length;

  return {
    conversations,
    handleConversation,
    isLoading: isLoading || isWebSearchLoading,
    total,
    handleFileUpload,
    webSearchHandler,
    googleDocsAppendHandler,
    webSearchQuery,
    isWebSearchLoading,
    isGoogleDocsLoading,
  };
}
