import React from "react";
import styled from "styled-components";
import SlackIcon from "theme/assets/images/slack-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faEnvelope, faFile } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
  margin: 0px 8px;
  padding: 4px 8px;
  border: 1px solid #4b5466;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.span`
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-style: bold;
  font-weight: 600;
  line-height: 24px;
  color: #4b5466;
  margin: 0px 0px 0px 4px;
`;

const StyledSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4b5466;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  title: string;
  icon?: string;
  onClickHandler: () => void;
  isLoading?: boolean;
}

function ActionButton({
  title,
  onClickHandler,
  icon,
  isLoading,
}: Props): JSX.Element {
  const clickHandler = () => {
    if (icon === "google-docs") {
      if (!isLoading) {
        onClickHandler();
      }
    } else {
      onClickHandler();
    }
  };
  return (
    <StyledContainer onClick={clickHandler}>
      {icon === "slack" && (
        <img src={SlackIcon} style={{ height: 20, width: 16 }} alt="slack" />
      )}
      {icon === "web" && (
        <FontAwesomeIcon icon={faGlobe} size="lg" color={"#4b5466"} />
      )}
      {icon === "email" && (
        <FontAwesomeIcon icon={faEnvelope} size="lg" color={"#4b5466"} />
      )}
      {icon === "google-docs" &&
        (isLoading ? (
          <StyledSpinner />
        ) : (
          <FontAwesomeIcon icon={faFile} size="lg" color={"#4b5466"} />
        ))}
      <StyledText>{title}</StyledText>
    </StyledContainer>
  );
}

export default ActionButton;
