import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V1 } from "shared/config";

const service = "agent/email";
const serviceUrl = `${API_URL}${API_VERSION_V1}${service}`;

export interface RequestPayload {
  receiver: string;
  subject: string;
  body: string;
  modelName?: string;
}

export interface EmailAgentResponse {
  data: {
    text: string;
  };
}

const postQuery = async (
  payload: RequestPayload
): Promise<EmailAgentResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post email agent query");
  }
  return await response.json();
};

export function useEmailAgentQuery(): UseMutationResult<
  EmailAgentResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}
