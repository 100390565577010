import { RequestPayload, useChartQuery2 } from "shared/api/v1/chart/basic/post";

interface ReturnType {
  isLoading: boolean;
  text: string;
  chartData: {
    data: Number[];
    labels: String[];
    unit: string;
    header: string;
  } | null;
}

export function useChartQuery(payload: RequestPayload): ReturnType {
  const [response] = useChartQuery2(payload);

  let text = "";
  let chartData = null;

  if (response?.data) {
    text = response?.data?.data?.text;
    chartData = response?.data?.data?.chartData;
  }

  return {
    isLoading: response?.isLoading,
    text,
    chartData,
  };
}
