import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FileList } from "shared/api/v1/file/lists/get";
import { useFileContextRemove } from "shared/modules/capitalmind/hooks/file-context-remove";
import PdfModal from "./pdf-modal";

const StyledContainer = styled.div`
  display: flex;
  margin: 8px 0px;
  position: relative;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px;
  background-color: #e3f2ff;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: underline;
  font-weight: 400;
  line-height: 24px;
  color: blue;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  padding: 12px;
  cursor: pointer;
`;

const StyledRemoveIconContainer = styled.div`
  padding: 2px;
  background-color: #f7f8fc;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
`;

interface Props {
  file: FileList;
  source: string;
}

function FileComponent({ file, source }: Props): JSX.Element {
  const { fileContextRemoveHandler } = useFileContextRemove(source || "demo");
  const removeFileContext = () => {
    fileContextRemoveHandler(file?._id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <PdfModal closeModal={closeModal} isModalOpen={isModalOpen} file={file} />
      <StyledContainer>
        <StyledUserChatBox>
          <StyledIconContainer>
            <FontAwesomeIcon icon={faFileAlt} size="3x" color={"black"} />
          </StyledIconContainer>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <StyledText onClick={() => setIsModalOpen(true)}>
              {file?.name}
            </StyledText>
          </div>
        </StyledUserChatBox>
        <StyledRemoveIconContainer onClick={removeFileContext}>
          <FontAwesomeIcon icon={faRemove} size="lg" color={"red"} />
        </StyledRemoveIconContainer>
      </StyledContainer>
    </>
  );
}

export default FileComponent;
