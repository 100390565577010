import { toast } from "react-toastify";
import {
  useGoogleDocsAppendAgentQuery,
  RequestPayload,
  GoogleDocsAppendAgentResponse,
} from "shared/api/v1/agent/google-docs/append/post";

interface ReturnType {
  isLoading: boolean;
  text: string;
  handleGoogleDocsAppend: (payload: RequestPayload) => void;
}

export function useGoogleDocsAppend(): ReturnType {
  const { mutate, isLoading, data } = useGoogleDocsAppendAgentQuery();

  const responseData = data as GoogleDocsAppendAgentResponse;

  const handleGoogleDocsAppend = (payload: RequestPayload) => {
    mutate(
      {
        ...payload,
        modelName: "mool-gpt-plus",
      },
      {
        onSuccess: (response) => {
          const text = response?.data?.text;
          toast.success(`${text}`);
        },
      }
    );
  };

  return {
    isLoading,
    text: responseData?.data?.text,
    handleGoogleDocsAppend,
  };
}
