import React from "react";
import styled from "styled-components";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Lottie from "react-lottie";
import ChatLoaderLottie from "theme/assets/lotties/chat-loader-lottie.json";
import { Source } from "shared/api/v1/web/search/post";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 0px 28px;
  flex-direction: column;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #4b5466;
  gap: 10px;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #4b5466;
`;

function ChatBoxAiWeb({
  text,
  isLoading,
  sources,
}: {
  text: string;
  isLoading?: boolean;
  sources: Source[];
}): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ChatLoaderLottie,
  };
  return (
    <StyledContainer>
      <StyledUserChatBox>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-start",
              margin: "-24px",
            }}
          >
            <Lottie options={defaultOptions} height={"80px"} />
          </div>
        ) : (
          <>
            <StyledText style={{ fontWeight: "600", color: "black" }}>
              Web search result :
            </StyledText>
            <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
            {sources && sources.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <StyledText style={{ color: "black" }}>Sources:</StyledText>
                {sources?.map((source) => {
                  return (
                    <div
                      key={source?.url}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px 16px",
                      }}
                    >
                      <a href={source?.url} target="blank">
                        <StyledText style={{ color: "blue" }}>
                          {source?.title}
                        </StyledText>
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default React.memo(ChatBoxAiWeb);
