import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { API_URL } from "shared/config";

const API_VERSION = "v1/";
const service = "chart/basic";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface RequestPayload {
  query: string;
}

export interface ChatResponse {
  data: {
    text: string;
    chartData: {
      data: Number[];
      labels: String[];
      unit: string;
      header: string;
    };
  };
}

const isEnabled = (text: string) => {
  if (text) {
    const markdownTableRegex = /\|.*\|.*\|/;
    if (markdownTableRegex.test(text)) {
      return true;
    }
  }
  return false;
};

const postQuery = async (payload: RequestPayload): Promise<ChatResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post chat query");
  }
  return await response.json();
};

export function useChartTextQuery(): UseMutationResult<
  ChatResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}

export function useChartQuery2(
  payload: RequestPayload
): [UseQueryResult<ChatResponse, unknown>, () => void] {
  const queryKey = `chart-query-${JSON.stringify(payload)}`;
  const response = useQuery<ChatResponse, unknown>(
    queryKey,
    () => postQuery(payload),
    {
      enabled: isEnabled(payload?.query),
    }
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
