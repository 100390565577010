import {
  useChatSequenceApi,
  ChatResponse,
  RequestPayload,
} from "shared/api/v1/internal/chat/sequence/post";
import { DocumentSource } from "shared/api/v1/web/search/post";
import { useGlobalState } from "shared/global-state";

interface ReturnType {
  isLoading: boolean;
  text: string;
  handleChat: (payload: RequestPayload) => void;
  threadId: string;
  documentSources: DocumentSource[];
  sqlQuery: string;
}

export function useChatSequence(repo?: string): ReturnType {
  const { mutate, isLoading, data } = useChatSequenceApi();
  const { globalState } = useGlobalState();
  const { llmModel, prompt, mool_prompt, prompt_dalberg } = globalState;

  const llm_prompt =
    repo === "demo"
      ? ""
      : repo === "mool"
      ? mool_prompt
      : repo === "dalberg"
      ? prompt_dalberg
      : prompt;

  const responseData = data as ChatResponse;

  const handleChat = (payload: RequestPayload) => {
    mutate({
      ...payload,
      modelName: payload?.modelName || llmModel,
      llmPrompt: llm_prompt,
      source: repo,
      namespace: repo,
    });
  };

  const documentSources = responseData?.data?.documentSources?.filter(
    (value, index, self) =>
      self.findIndex((val) => val.uuid === value.uuid) === index
  );

  return {
    isLoading,
    text: responseData?.data?.text,
    handleChat,
    threadId: responseData?.data?.threadId,
    documentSources,
    sqlQuery: responseData?.data?.sqlQuery,
  };
}
