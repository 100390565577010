import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Conversation } from "shared/modules/mool/hooks/conversation";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { RequestPayload } from "shared/api/v1/agent/email/post";
import { toast } from "react-toastify";
import { useEmailAgent } from "shared/modules/mool/hooks/email-agent";

const StyledRemoveIconContainer = styled.div`
  padding: 2px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 28px;
  flex-direction: column;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #4b5466;
  gap: 10px;
`;

const StyledTextInput = styled.input`
  font-family: Poppins;
  background-color: transparent;
  border: 0px;
  width: 100%;
  outline: none;
  font-size: 16px;
  resize: none;
  box-sizing: "border-box";
  overflow: hidden;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #024584;
  width: 200px;
  justify-content: center;
  font-weight: 600;
  line-height: 26px;
  color: white;
`;

const StyledPreferenceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPreferenceItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 15%;
`;

const StyledLabelText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const StyledInputContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => (props?.isFocused ? "#ffffff" : "#eff2f6")};
  border: 1px solid black;
  width: 50%;
  flex-wrap: wrap;
  overflow-wrap: break-word;
`;

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  conversations: Conversation[];
}

const customStyles = {
  content: {
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -33%)",
    width: "80%",
    height: "85%",
  },
};

function EmailModal({
  isModalOpen,
  closeModal,
  conversations,
}: Props): JSX.Element {
  const { handleEmailAgent, isLoading: isSlackLoading, text } = useEmailAgent();

  const [receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    const lastAiMessage = conversations.filter(
      (conversation) =>
        conversation.type === "ai" || conversation.type === "ai-web"
    );
    const index = lastAiMessage?.length - 1;
    const aiMessage = lastAiMessage[index]?.text;
    setBody(aiMessage);
  }, [conversations.length, isModalOpen]);

  useEffect(() => {
    if (text) {
      toast.success(text);
    }
  }, [text]);

  const sendMessage = () => {
    if (receiver && subject && body) {
      const payload = {
        receiver,
        subject,
        body,
      } as RequestPayload;
      handleEmailAgent(payload);
    }
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <StyledRemoveIconContainer onClick={closeModal}>
        <FontAwesomeIcon icon={faRemove} size="lg" color={"black"} />
      </StyledRemoveIconContainer>

      <StyledUserChatBox>
        <StyledPreferenceItemContainer>
          <StyledPreferenceItem>
            <StyledLabelText>To:</StyledLabelText>
          </StyledPreferenceItem>
          <StyledInputContainer isFocused={true}>
            <StyledTextInput
              placeholder="Receiver email"
              value={receiver}
              onChange={(e) => setReceiver(e.target.value)}
            />
          </StyledInputContainer>
        </StyledPreferenceItemContainer>
        <StyledPreferenceItemContainer>
          <StyledPreferenceItem>
            <StyledLabelText>Subject:</StyledLabelText>
          </StyledPreferenceItem>
          <StyledInputContainer isFocused={true}>
            <StyledTextInput
              placeholder="Subject for the email"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </StyledInputContainer>
        </StyledPreferenceItemContainer>
        <StyledLabelText>Body:</StyledLabelText>
        <div style={{ padding: "0px 24px" }}>
          <Markdown remarkPlugins={[remarkGfm]}>{body}</Markdown>
        </div>
      </StyledUserChatBox>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {isSlackLoading ? (
          <StyledButton>Sending...</StyledButton>
        ) : (
          <StyledButton onClick={sendMessage}>Send Email</StyledButton>
        )}
      </div>
    </Modal>
  );
}

export default EmailModal;
