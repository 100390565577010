import React from "react";
import Modal from "react-modal";
import { FileList } from "shared/api/v1/file/lists/get";
import { Viewer } from "@react-pdf-viewer/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const StyledRemoveIconContainer = styled.div`
  padding: 2px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  file: FileList;
}

const customStyles = {
  content: {
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -33%)",
    width: "80%",
    height: "85%",
  },
};

function PdfModal({ isModalOpen, closeModal, file }: Props): JSX.Element {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <StyledRemoveIconContainer onClick={closeModal}>
        <FontAwesomeIcon icon={faRemove} size="lg" color={"black"} />
      </StyledRemoveIconContainer>
      <Viewer fileUrl={file?.fileUrl} plugins={[defaultLayoutPluginInstance]} />
    </Modal>
  );
}

export default PdfModal;
