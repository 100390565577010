import React, { useState } from "react";
import styled from "styled-components";
import { FileList } from "shared/api/v1/file/lists/get";
import PdfModal from "./pdf-modal";

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: underline;
  font-weight: 400;
  line-height: 24px;
  color: blue;
`;

interface Props {
  file: FileList;
}

function SourceDocument({ file }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <PdfModal closeModal={closeModal} isModalOpen={isModalOpen} file={file} />
      <StyledText>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setIsModalOpen(true)}
        >
          {file?.name}
        </span>
      </StyledText>
    </>
  );
}

export default SourceDocument;
