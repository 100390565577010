import { useMutation, UseMutationResult } from "react-query";
import { DocumentSource } from "shared/api/v1/web/search/post";
import { API_URL } from "shared/config";

const API_VERSION = "v1/";
const service = "internal/chat/sequence";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface RequestPayload {
  query: string;
  threadId: string;
  modelName?: string;
  llmPrompt?: string;
  source?: string;
  temperature?: number;
  namespace?: string;
}

export interface ChatResponse {
  data: {
    text: string;
    threadId: string;
    documentSources: DocumentSource[];
    sqlQuery: string;
  };
}

const postQuery = async (payload: RequestPayload): Promise<ChatResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post chat query");
  }
  return await response.json();
};

export function useChatSequenceApi(): UseMutationResult<
  ChatResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}
