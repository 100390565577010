import {
  useFileListQuery,
  FileList,
  FileListRequestPayload,
} from "shared/api/v1/file/lists/get";

interface ReturnType {
  isLoading: boolean;
  files: FileList[];
  refreshFileList: () => void;
}

export function useFileList(payload: FileListRequestPayload): ReturnType {
  const [{ data, isLoading }, refreshFileList] = useFileListQuery(payload);

  let files: FileList[] = [];
  if (data && data?.data?.files) {
    files = data?.data?.files;
  }

  return {
    isLoading,
    refreshFileList,
    files,
  };
}
