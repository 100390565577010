import {
  usePdfUpload,
  UploadPdfRequestPayload,
} from "shared/api/v1/file/upload/pdf/post";
import { useFileList } from "./file-list";

interface Return {
  fileUploadHandler: (payload: UploadPdfRequestPayload) => void;
  isLoading: boolean;
}

export function useFileUpload({ source }: { source: string }): Return {
  const { refreshFileList } = useFileList({ source });

  const { mutate, isLoading } = usePdfUpload();

  const fileUploadHandler = (payload: UploadPdfRequestPayload) => {
    mutate(payload, {
      onSuccess: () => {
        setTimeout(() => {
          refreshFileList();
        }, 200);
      },
    });
  };

  return { fileUploadHandler, isLoading };
}
