import { useMutation, UseMutationResult } from "react-query";
import { API_URL } from "shared/config";

const API_VERSION = "v1/";
const service = "file";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface RemoveContextPayload {
  id: string;
}

export interface RemoveContextResponse {
  file: {
    _id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    isActive: boolean;
  };
}

const removeFileContextQuery = async (
  payload: RemoveContextPayload
): Promise<RemoveContextResponse> => {
  const response = await fetch(`${serviceUrl}/${payload?.id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to delete file");
  }
  return await response.json();
};

export function useFileContextRemoveMutation(): UseMutationResult<
  RemoveContextResponse,
  unknown,
  RemoveContextPayload,
  unknown
> {
  return useMutation((payload: RemoveContextPayload) =>
    removeFileContextQuery(payload)
  );
}
