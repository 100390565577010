import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { API_URL } from "shared/config";
import { queryStringParser } from "shared/network/utils";

const API_VERSION = "v1/";
const service = "file/lists";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface FileListRequestPayload {
  source: string;
}

export interface FileList {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  fileUrl: string;
}

export interface FileListResponse {
  data: {
    files: FileList[];
  };
}

const getFileListQuery = async (
  payload: FileListRequestPayload
): Promise<FileListResponse> => {
  const queryString = queryStringParser<FileListRequestPayload>(payload);
  const response = await fetch(`${serviceUrl}?${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch lists");
  }
  return await response.json();
};

export function useFileListQuery(
  payload: FileListRequestPayload
): [UseQueryResult<FileListResponse, unknown>, () => void] {
  const queryKey = `file-lists-${JSON.stringify(payload)}`;
  const response = useQuery<FileListResponse, unknown>(queryKey, () =>
    getFileListQuery(payload)
  );

  const client = useQueryClient();
  const refreshResponse = () => {
    client.invalidateQueries(queryKey);
  };
  return [response, refreshResponse];
}
