import { useMutation, UseMutationResult } from "react-query";

import { API_URL } from "shared/config";
const API_VERSION = "v1/";
const service = "file/upload/pdf";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface UploadPdfRequestPayload {
  files: File[];
  source: string;
}

export type UploadResponse = string;

const uploadPdf = async (
  payload: UploadPdfRequestPayload
): Promise<UploadResponse> => {
  const formData = new FormData();
  const files = payload?.files;
  const source = payload?.source;
  formData.append("source", source);
  files.forEach((file) => {
    formData.append("file", file, file.name);
  });
  const response = await fetch(serviceUrl, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) {
    throw new Error("Failed to upload file");
  }
  return await response.json();
};

export function usePdfUpload(): UseMutationResult<
  UploadResponse,
  unknown,
  UploadPdfRequestPayload,
  unknown
> {
  return useMutation((payload: UploadPdfRequestPayload) => uploadPdf(payload));
}
