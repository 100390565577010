import { useMutation, UseMutationResult } from "react-query";
import { API_URL, API_VERSION_V1 } from "shared/config";

const service = "agent/slack";
const serviceUrl = `${API_URL}${API_VERSION_V1}${service}`;

export interface RequestPayload {
  message: string;
  receiver: string;
  isChannel: boolean;
  tags?: string[];
  modelName?: string;
}

export interface SlackAgentResponse {
  data: {
    text: string;
  };
}

const postQuery = async (
  payload: RequestPayload
): Promise<SlackAgentResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post slack agent query");
  }
  return await response.json();
};

export function useSlackAgentQuery(): UseMutationResult<
  SlackAgentResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}
