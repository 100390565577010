import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChart({
  chartData,
}: {
  chartText: string;
  chartData: {
    data: Number[];
    labels: String[];
    unit: string;
    header: string;
  };
}): JSX.Element {
  const chartRef = useRef<any>(null);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: chartData?.unit,
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
    },
  };

  const data = {
    labels: chartData?.labels,
    datasets: [
      {
        label: chartData?.header,
        data: chartData?.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const exportfunc = () => {
    const link = document.createElement("a");
    link.download = "chart.jpeg";
    if (chartRef?.current) {
      link.href = chartRef.current.toBase64Image("image/jpeg", 1);
      link.click();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
        padding: "16px",
        borderRadius: "4px",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignSelf: "flex-end",
          fontSize: "12px",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={exportfunc}
      >
        Export
      </div>
      <Line options={options} data={data} ref={chartRef} />
    </div>
  );
}

export default LineChart;
