import { useFileContextRemoveMutation } from "shared/api/v1/file/{id}/delete";
import { useFileList } from "./file-list";

interface Return {
  fileContextRemoveHandler: (id: string) => void;
  isLoading: boolean;
}

export function useFileContextRemove(repo: string): Return {
  const { mutate, isLoading } = useFileContextRemoveMutation();
  const { refreshFileList } = useFileList({ source: repo });

  const fileContextRemoveHandler = (id: string) => {
    mutate(
      { id },
      {
        onSettled: () => {
          // refresh
          refreshFileList();
        },
      }
    );
  };

  return { fileContextRemoveHandler, isLoading };
}
