import { useMutation, UseMutationResult } from "react-query";
import { API_URL } from "shared/config";

const API_VERSION = "v1/";
const service = "web/search";
const serviceUrl = `${API_URL}${API_VERSION}${service}`;

export interface RequestPayload {
  query: string;
  threadId: string;
  modelName?: string;
  llmPrompt?: string;
  source?: string;
}

export interface Source {
  title: string;
  img_url: string;
  url: string;
}
export interface DocumentSource {
  file: string;
  originalName: string;
  "loc.lines.from": number;
  "loc.lines.to": number;
  uuid: string;
}

export interface ChatResponse {
  data: {
    text: string;
    sources: Source[];
    threadId: string;
  };
}

const postQuery = async (payload: RequestPayload): Promise<ChatResponse> => {
  const response = await fetch(serviceUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to post chat query");
  }
  return await response.json();
};

export function useWebSearchQueryApi(): UseMutationResult<
  ChatResponse,
  unknown,
  RequestPayload,
  unknown
> {
  return useMutation((payload: RequestPayload) => postQuery(payload));
}
