import React from "react";
import styled from "styled-components";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Lottie from "react-lottie";
import ChatLoaderLottie from "theme/assets/lotties/chat-loader-lottie.json";
import LineChart from "./charts/line-chart";
import { useChartQuery } from "shared/modules/mool/hooks/chart-query";
import { DocumentSource } from "shared/api/v1/web/search/post";
import SourceDocument from "./source-document";
import { FileList } from "shared/api/v1/file/lists/get";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 0px 28px;
  flex-direction: column;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #4b5466;
  gap: 10px;
`;

const StyledText = styled.span`
  color: black;
  font-family: Poppins;
  font-size: 18px;
  font-style: bold;
  font-weight: 600;
  line-height: 24px;
`;

const StyledTextLogo = styled.span`
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledImageContainer = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color || "#024584"};
  height: 20px;
  width: 20px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.color || "#024584"};
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ChatBoxAi({
  text,
  isLoading,
  documentSources,
  sqlQuery,
  isWebSearchLoading,
  label,
  labelColor,
}: {
  text: string;
  isLoading?: boolean;
  documentSources?: DocumentSource[];
  sqlQuery?: string;
  isWebSearchLoading?: boolean;
  label: string;
  labelColor?: string;
}): JSX.Element {
  const {
    text: chartText,
    chartData,
    isLoading: isChartLoading,
  } = useChartQuery({ query: text });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ChatLoaderLottie,
  };
  return (
    <StyledContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <StyledImageContainer color={labelColor}>
          <StyledTextLogo>{label[0].toUpperCase()}</StyledTextLogo>
        </StyledImageContainer>
        <StyledText>{label}</StyledText>
      </div>
      <StyledUserChatBox>
        {isWebSearchLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-start",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "-24px" }}>
              <Lottie options={defaultOptions} height={"80px"} />
            </div>
            <StyledText
              style={{
                color: "#4b5466",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Searching web for : {`${text}`}
            </StyledText>
            <div style={{ margin: "-24px" }}>
              <Lottie options={defaultOptions} height={"80px"} />
            </div>
          </div>
        ) : isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-start",
              margin: "-24px",
            }}
          >
            <Lottie options={defaultOptions} height={"80px"} />
          </div>
        ) : (
          <>
            <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
            {isChartLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ margin: "-24px" }}>
                  <Lottie options={defaultOptions} height={"80px"} />
                </div>
                <StyledText
                  style={{
                    color: "#4b5466",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Preparing chart
                </StyledText>
                <div style={{ margin: "-24px" }}>
                  <Lottie options={defaultOptions} height={"80px"} />
                </div>
              </div>
            )}
            {chartData &&
              chartData?.data &&
              Array.isArray(chartData?.data) &&
              chartData?.labels &&
              Array.isArray(chartData?.labels) &&
              chartData?.labels.length > 0 &&
              chartData?.data.length > 0 && (
                <div style={{ display: "flex", width: "40%" }}>
                  <LineChart chartText={chartText} chartData={chartData} />
                </div>
              )}
            {documentSources &&
              documentSources.length > 0 &&
              sqlQuery === "" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <StyledText style={{ color: "black", fontSize: "16px" }}>
                    Related Documents:
                  </StyledText>
                  {documentSources?.map((source) => {
                    return (
                      <div
                        key={source?.file}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "8px 16px",
                        }}
                      >
                        <SourceDocument
                          file={
                            {
                              fileUrl: source?.file,
                              name: source?.originalName,
                            } as FileList
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            {sqlQuery && sqlQuery !== "--invalid--" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "16px 0px",
                }}
              >
                <StyledText style={{ color: "black", fontSize: "16px" }}>
                  Citation:
                </StyledText>
                <Markdown remarkPlugins={[remarkGfm]}>{sqlQuery}</Markdown>
              </div>
            )}
          </>
        )}
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default React.memo(ChatBoxAi);
