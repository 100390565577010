import { useGlobalState } from "shared/global-state";

const LLM_PREFERENCES = [
  { key: "google-vertex-ai", label: "Google Vertex AI" },
  { key: "open-ai-gpt", label: "OpenAI ChatGPT" },
];

interface LLM {
  key: string;
  label: string;
}

interface Return {
  llmPreferences: LLM[];
  selectedLlmPreference: LLM;
  updateLlmPreference: (key: string) => void;
  prompt: string;
  updatePrompt: (promptArg: string) => void;
}

export function useLlmPreference(repo?: string): Return {
  const { globalState, setGlobalState } = useGlobalState();

  const updateLlmPreference = (key: string) => {
    setGlobalState({ llmModel: key });
  };

  const updatePrompt = (promptArg: string) => {
    if (repo === "demo") {
      setGlobalState({ demo_prompt: promptArg });
    } else if (repo === "mool") {
      setGlobalState({ mool_prompt: promptArg });
    } else if (repo === "dalberg") {
      setGlobalState({ prompt_dalberg: promptArg });
    } else {
      setGlobalState({ prompt: promptArg });
    }
  };

  const selectedLlmPreference = LLM_PREFERENCES.filter(
    (llm) => llm.key === globalState?.llmModel
  )[0];

  return {
    llmPreferences: LLM_PREFERENCES,
    selectedLlmPreference,
    updateLlmPreference,
    prompt:
      repo === "dalberg"
        ? globalState.prompt_dalberg
        : repo === "demo"
        ? globalState?.demo_prompt
        : repo === "mool"
        ? globalState.mool_prompt
        : globalState.prompt,
    updatePrompt,
  };
}
