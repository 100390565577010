import React from "react";
import { Routes, Route } from "react-router-dom";

import ChatModule from "../modules/chat";

function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ChatModule />} />
    </Routes>
  );
}

export default AppRoutes;
